<template>
  <div class="d-flex flex-column">
    <div>
      <component
        :is="isEvent && (!item.is_active || !item.is_live) || isBlocked ? 'div' : 'link-wrapper'"
        :link="item | linkNormalisation"
        :item="item"
        :is-vod="isVod"
        class="carousel-card"
        draggable="false"
        ondragstart="return false;"
        @click="onClick()"
        @mouseenter="(isVod || isEvent) && $emit('onMouseEnter', item)"
        @mouseleave="(isVod || isEvent) && $emit('onMouseLeave', item)"
      >
        <card-thumb-image
          :id="id"
          :class="{ 'rounded-circle': shape_type === 'circle' }"
          :item="item"
          :aspect-ratio="aspectRatio"
          :responsive-main-style="aspectRatio === '27x40' ? 'border-radius: 8px;' : ''"
          :broadcasting-status="isEvent ? ((item.is_active && item.is_live) || item.start).toString() : ''"
        />
        <div :class="textBlockStyleSwitcher()">
          <trim-el
            :class="{ centered: shape_type === 'circle' }"
            class="carousel-card-title"
            :row-num="item.itype === 'shop_product' ? 1 : null"
            :text="title"
            tag="h3"
          />
          <div v-if="item.itype === 'item_epg_shop_event'">
            <div
              v-if="item.creator && item.creator.name"
              class="carousel-card-subtitle-wrapper"
            >
              <trim-el
                class="carousel-card-subtitle"
                :text="item.creator.name"
                tag="h4"
              />
            </div>
          </div>
          <span
            v-if="item.itype === 'shop_product' && item.price"
            class="d-flex align-self-start"
          >
            <div class="actual-price">
              {{ getFormattedCurrency(item.currency) || '$' }}{{ item.price }}
            </div>
            <div v-if="item.original_price" class="old-price ml-1">
              {{ getFormattedCurrency(item.currency) || '$' }}{{ item.original_price }}
            </div>
          </span>
        </div>
      </component>
    </div>
    <span
      v-if="item.itype === 'screen_shoprz' && item.creator"
      class="d-flex align-self-center"
    >
      <button
        v-if="isFollowed(item.creator.id)"
        class="follow_btn follow_btn-unfollowed follow_btn-home mb-1"
        @click="toggleFollow(item.creator.id)"
      >
        Following
      </button>
      <button
        v-else
        class="follow_btn follow_btn-home mb-1"
        @click="toggleFollow(item.creator.id)"
      >
        Follow
      </button>
    </span>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { amsClient } from '@/service/ams';
  import Config from '@/service/config';
  import rtm from '@/service/realTimeMessages';
  import contentLanguage from '@/filters/contentLanguage.js';
  import { FollowMixin } from '@/plugins/mixin';
  import { getFormattedCurrency } from 'vimmi-web-utils/cjs/formatCurrency';

  import TrimEl from '@/components/common/trim-el';
  import CardThumbImage from '@/components/cards/card-thumb-image.vue';
  import LinkWrapper from '@/components/link-wrapper';

  export default {
    name: 'CardCarusel',
    components: {
      TrimEl,
      CardThumbImage,
      LinkWrapper,
    },
    mixins: [FollowMixin],
    props: {
      item: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      // eslint-disable-next-line vue/require-prop-type-constructor
      shapeType: '',
      aspectRatio: {},
      buhContext: null,
    },
    data() {
      return {
        isBlocked: false,
      };
    },
    computed: {
      ...mapState('checkout', ['checkoutId']),
      isVod() {
        return this.item?.itype?.includes('vod');
      },
      isEvent() {
        return this.item.itype === 'item_epg_shop_event';
      },
      currentlink() {
        return this.$route.path;
      },
      shape_type() {
        return this.shapeType || 'vertical';
      },
      title() {
        return contentLanguage(this.item, 'title');
      },
    },
    created() {
      this._origHref = location.href;
    },
    mounted() {
      this.isMaxAtt();
    },
    methods: {
      isMaxAtt() {
        if (this.isEvent && (this.item.is_active && this.item.is_live && this.item.on_air)) {
          let att = 0;
          let limit = 0;

          rtm.on('stats', async (data) => {
            att = await data.msg[this.item.id];
          });

          rtm.on('license_package', async (data) => {
            if (data.room === this.item.id) {
              limit = await data.msg.attendees_limit;
            }

            if (att > limit) {
              this.isBlocked = true;
            }
          });
        }
      },
      getFormattedCurrency: getFormattedCurrency,
      async onClick() {
        Config.set(
          'temp.context',
          this.buhContext
            ? this.buhContext
            : this._origHref.indexOf('search') === -1
              ? 'user_click'
              : 'search',
        );

        const options = this.$stats.formatEventDate(this.item);
        this.$stats.send('object_click', { ...options, position: this.position });

        if (this.isVod) {
          const vodWithChat = await amsClient.callAms(`/get_item/${this.item.id}/`, { cache: false });

          const { event, ...item } = vodWithChat.head;

          this.$bus.$emit('togglePopupVOD', {
            item: {
              ...item,
              ...(event && { chat_url: event.chat_url, chat_id: event.chat_id }),
            },
          });
        }

        if (this.item.itype === 'shop_product') {
          this.$bus.$emit('toggleProductPopup', { item: this.item });
        }

        if (this.isEvent && (!this.item.is_active || !this.item.is_live)) {
          this.$bus.$emit('ToggleScheduleEventDialog', this.item);
        }

        if (this.isBlocked) {
          this.$bus.$emit('toggleMessagePopup', { item: this.item });
        }
      },
      textBlockStyleSwitcher() {
        if (this.item.itype === 'shop_product' && this.item.price) {
          return 'carousel-card-text-block--product';
        }
        if (this.item.itype === 'item_epg_shop_event') {
          return 'carousel-card-text-block--event';
        }
        return 'carousel-card-text-block';
      },
    },
  };
</script>

<style lang="scss" src="./_carousel-component.scss"></style>
